<template>
  <ItemEditTemplate ref=template v-bind="{resource, item, loading, creating}" v-on="{save,cancel}" v-if="item">
    <div class="row row-cols-1 row-cols-md-2 mt-4">
      <div class="col mb-4">
        <merchant-card v-if="userIsSuperAdmin" :item="item" />
        <b-card>
          <template #header>
            <i class="bx bxs-component"></i>
            {{ $t("batches.label") }}
          </template>
          <form-input 
            label="common.name" 
            icon="bx-purchase-tag"
            v-model="item.name"
            rules="required"
          />
          <form-input
            type="item" 
            label="shipments.pickup-location" 
            icon="bx-map" 
            v-model="item.pickup_location_id"
            resource-name="locations"
            rules="required"
            :disabled="!item.merchant_id"
            :refreshOn="item.merchant_id"
            :scope="['pickup','active','approved',['merchant', item.merchant_id]]"
            :clearable="false"
          />

          <template v-if="features & FEATURE_CONTENT">
            <form-input 
              label="shipments.contents" 
              icon="bx-detail"
              type="select"
              v-model="item.contents" 
              :reduce="c => c" 
              :options="preferences.content_types" 
              :clearable="true" 
              :placeholder="$t('packages.singular')"
              :description="$t('batches.contents-desc')"
            />
          </template>
          <template v-else>
            <form-input 
              label="shipments.contents" 
              icon="bx-detail"
              v-model="item.contents"
              :placeholder="$t('packages.singular')"
              :description="$t('batches.contents-desc')"
            />
          </template>

        </b-card>


        <b-card no-body v-if="!creditCards">
          <template #header> 
            <i class='bx bxs-package' />
            {{$t('batches.packages')}}
          </template>

          <div class="container my-3">
            <div class="row py-1" v-for="{code: size,label} in sizes" :key="size">
              <template v-if="size !== 'OVERSIZED'">
                <div class="col">
                  {{$t(label)}} <br>
                  <span class="small text-muted">{{$t(`packages.dimensions.${size}`)}}</span>
                </div>
                <div class="col">
                  <b-form-spinbutton 
                    class="float-right"
                    :style="{maxWidth: '10rem'}" 
                    v-model="item[`packages_${size.toLowerCase()}`]" 
                    min="0" 
                    max="100"
                  ></b-form-spinbutton>
                </div>
              </template>
            </div>
          </div>

        </b-card>

        <b-card v-if="creating">
          <template #header>
            <i class="bx bxs-group"></i>
            {{ $t("batches.customers") }}
          </template>

          <form-input 
            label="batches.upload.file" 
            icon="bx-file"
            type="file"
            v-model="file"
            rules="required"
            :placeholder="$t('batches.upload.placeholder')"
            :drop-placeholder="$t('batches.upload.drop-placeholder')"
            :description="$t('batches.upload.info')"
            accept=".xlsx, .xls, .ods, .csv"
          />

          <b-button variant="info" @click="downloadTemplate">
            <i class='bx bxs-download' ></i>
            {{ $t('batches.download-template') }}
          </b-button>

        </b-card>

      </div>
      <div class="col mb-4">
        <b-card>
          <template #header>
            <i class="bx bxs-message-detail"></i>
            {{ $t("batches.notifications") }}
          </template>
            <form-input 
              type="select"
              bitmask
              label="batches.notification-channels" 
              icon="bx bx-broadcast"
              rules="required|gt0"
              :options="resource.channels | transMap"
              v-model="item.channels"
            />
            <form-input 
              type="textarea"
              icon="bx-message-detail"
              rules="required"
              label="batches.notification-message"
              v-model="item.settings.message"
              :description="$t(`batches.message-desc${descSuffix}`)"
            />

            <div class="row py-1" v-if="creditCards">
                <div class="col-lg-7">
                  {{$t('batches.initial-notification')}} <br>
                  <span class="small text-muted">{{$t(`batches.initial-notification-desc`)}}</span>
                </div>
                <div class="col-lg-5">
                  <b-form-spinbutton 
                    class="float-lg-right"
                    :style="{maxWidth: '10rem'}" 
                    v-model="item.settings.offset" 
                    min="0"
                  ></b-form-spinbutton>
                </div>
            </div>

        </b-card>
        <b-card>
          <template #header>
            <i class="bx bxs-message-error"></i>
            {{ $t("batches.reminders") }}
          </template>
          <div class="row py-1 mb-3">
            <div class="col-lg-7">
              {{$t('batches.reminder-count')}} <br>
              <span class="small text-muted">{{$t(`batches.reminder-count-desc`)}}</span>
            </div>
            <div class="col-lg-5">
              <b-form-spinbutton 
                class="float-lg-right"
                :style="{maxWidth: '10rem'}" 
                v-model="item.settings.reminder_count" 
                min="0"
              ></b-form-spinbutton>
            </div>
          </div>

          <div :class="{'muted': !item.settings.reminder_count}">
            <form-input 
              type="textarea"
              icon="bx-message-error"
              :rules="{'required': item.settings.reminder_count > 0}"
              label="batches.reminder-message"
              v-model="item.settings.reminder"
              :description="$t(`batches.message-desc${descSuffix}`)"
              :disabled="!item.settings.reminder_count"
            />
            <div class="row py-1 mb-3">
              <div class="col-lg-7">
                {{$t('batches.reminder-interval')}} <br>
                <span class="small text-muted">{{$t(`batches.reminder-interval-desc`)}}</span>
              </div>
              <div class="col-lg-5">
                <b-form-spinbutton 
                  class="float-lg-right"
                  :style="{maxWidth: '10rem'}" 
                  v-model="item.settings.reminder_interval" 
                  min="0"
                  :disabled="!item.settings.reminder_count"
                ></b-form-spinbutton>
              </div>
            </div>

            <form-input 
              class="m-0"
              type="checkbox"
              switch
              size="lg" 
              label="batches.custom-final-reminder" 
              v-model="item.settings.custom_final_reminder"
              :disabled="item.settings.reminder_count < 2"
            />

            <form-input 
              class="mt-2"
              v-if="item.settings.custom_final_reminder && item.settings.reminder_count >= 2"
              type="textarea"
              icon="bx-message-x"
              label="batches.final-reminder"
              v-model="item.settings.final_reminder"
              :description="$t(`batches.message-desc${descSuffix}`)"
              :disabled="item.settings.reminder_count < 2"
            />

          </div>

        </b-card>
      </div>
    </div>
  </ItemEditTemplate>
</template>

<script>
import ItemEdit from "@/components/ItemEdit";
import ItemEditTemplate from "@/components/ItemEditTemplate";
import FormInput from "@/components/Form/Input";
import MerchantCard from "@/components/Form/MerchantCard";
import Package from '@/resources/Package';
import { FEATURE_BATCH_CREDIT_CARDS, FEATURE_CONTENT } from '@/resources/Merchant';
import api from '@/api';
import { download } from '@/utils';

export default {
  extends: ItemEdit,
  components: { ItemEditTemplate, FormInput, MerchantCard },
  data() {
    return {
      file: null,
      sizes: Package.sizes,
      FEATURE_CONTENT,
    }
  },
  computed: {
    creditCards() {
      return !!((this.creating ? this.features : this.item.features) & FEATURE_BATCH_CREDIT_CARDS)
    },
    totalPackages() {
      return this.sizes.map(s => s.code).reduce((c,size) => (c+=this.item[`packages_${size.toLowerCase()}`],c),0)
    },
    descSuffix() {
      return this.creditCards ? '-cc' : '';
    }
  },
  methods: {
    async beforeSave() {
      if(this.creating) {
        let formData = new FormData()
        formData.append('file', this.file)
        let {data: filename} = await api.post('batches/upload', formData)
        this.item.file = filename
      }
    },
    saveFailed(){
      this.file = null
    },
    async downloadTemplate() {
      download(await api.get('batches/template',{responseType: 'blob'}))
    },
  }
};
</script>